import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import ProductionPage from './production';
export const pageQuery = graphql`
  query ProductionEnQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    file(relativePath: { eq: "production.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
export const _frontmatter = {
  "type": "production",
  "langKey": "en",
  "title": "Production"
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = ProductionPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Only best materials are used to create Sharp Blades products`}</h2>
    <p>{`The action of making or manufacturing from components or raw materials, or the process of being so manufactured.`}</p>
    <h2>{`Lifetime warranty`}</h2>
    <p>{`Our products are the best quality only but use them in purpose to which they were made to. If a product is damaged during the activity which wasn’t intended for it, there is no way of using warranty.`}</p>
    <p>{`Of course if it is not the case, contact us about any fault and we will individually solve it to your full satisfaction.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      